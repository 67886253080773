import React from 'react';
import ReactDOM from 'react-dom';
// import Birthday2021 from './2021';
// import Birthday2022 from './2022';
import Birthday2023 from './2023';

ReactDOM.render(
  <React.StrictMode>
    <Birthday2023 />
  </React.StrictMode>,
  document.getElementById('root')
);
