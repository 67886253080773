import "./index.css";
import React, { useRef, useState } from "react";
import Confetti from "react-confetti";
import Machine from "./machine";
import Cake from "./cake";
import {
  AGE,
  PASS_DAYS,
  MEET_DAYS,
  audioUrl,
  LOVE_DAYS,
  WEDDING_DAYS,
  CUR_YEAR,
  START_YEAR,
} from "../constants";

const CANDLES_PRESETS = new window.URLSearchParams(window.location.search).get(
  "candles"
);
const CANDLES = parseInt(CANDLES_PRESETS)
  ? parseInt(CANDLES_PRESETS)
  : CANDLES_PRESETS === "age"
  ? AGE
  : 1;

function App() {
  const [isShowText, setIsShowText] = useState(false);
  const audioRef = useRef(null);

  const playMusic = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    }
  };

  const showText = () => {
    setIsShowText(true);
  };

  const sayText = (
    <div className="say-text">
      <span>祝媳妇{AGE}岁生日快乐!</span>
      <br />
      <br />
      <p className="say">
        媳妇，今天是我们认识的第<strong>{PASS_DAYS}</strong>天，见面的第
        <strong>{MEET_DAYS}</strong>天，我们在一起的第
        <strong>{LOVE_DAYS}</strong>天，结婚之后的第
        <strong>{WEDDING_DAYS}</strong>天。
        <br />
        <br />
        今年是我们的第<strong>{CUR_YEAR - START_YEAR}</strong>个生日，未来还有
        <strong>{99 - AGE}</strong>个生日要过~。
        <br />
        <br />
        今年是我们组建家庭的第二年，这一年发生了很多事情，酸甜苦辣，我们逐渐在适应、融入、包容、理解彼此，
        我的心智成熟的比较慢，在我们的婚姻中、家庭中作为老公的角色转变也比较慢，这个过程给媳妇带来了很多负担，让媳妇承担了很多委屈，也感谢媳妇多次给我机会，让老公逐渐踏上正轨。
        <br />
        <br />
        今年我们也解决了媳妇的心跳的问题，期间我做的表现很差，回想起来现在我也很自责，有失媳妇的期望，相信我会调整。
        无论如何这个长期以往的问题基本得到了解决，也是我们值得欣慰的一件事。
        <br />
        <br />
        我们经历过的所有事情都是好事，锻炼了我们的心性，让我们心智得到成长，我们对这些经历说声谢谢。
        <br />
        <br />
        今年还要感谢媳妇帮我完成了出游的计划，深圳成为了我去过最远的城市，以前去一个稍微远一点的陌生的城市的时候，总会觉得不踏实，有媳妇的陪伴之后也觉得到哪都很踏实。
        <br />
        <br />
        新的一年我们万事不要有压力，顺其自然，放宽心态，这样才能有一个好的身体。
        <br />
        <br />
        新的一岁希望媳妇可以工作顺利，得偿所愿，体健心安。
        <br />
        <br />
        相信我们的家庭也一定会越来越和谐，生活也会蒸蒸日上。
        <br />
        <br />
        Always Day One
        <br />
        善意假设 & 共同成长
        <br />
        <br />
        最后，今晚媳妇也要睡个好觉，晚安~
        <br />
        <br />
      </p>
      <br />
      <span className="love">❤</span> 来自老公王宗旭的祝福
    </div>
  );

  return (
    <React.Fragment>
      <audio ref={audioRef} src={audioUrl} loop />
      {!isShowText ? (
        <Machine onFinish={playMusic} onNext={showText} />
      ) : (
        <React.Fragment>
          <Cake age={CANDLES}>{sayText}</Cake>
          <Confetti
            numberOfPieces={150}
            width={document.body.offsetWidth}
            height={document.body.offsetHeight}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default App;
