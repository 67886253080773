import { getPassDay } from "./utils";

export const BIRTHDAY_YEAR_YIN = 1993
export const BIRTHDAY_MONTH_YIN = 12
export const BIRTHDAY_DAY_YIN = 1

export const BIRTHDAY_YEAR_YANG = 1994
export const BIRTHDAY_MONTH_YANG = 1
export const BIRTHDAY_DAY_YANG = 12

export const START_YEAR = 2020
export const START_AGE = 27

export const CUR_YEAR = new Date().getFullYear()

// wechat
export const PASS_DAYS = getPassDay(2020, 6, 13)

// meet offline
export const MEET_DAYS = getPassDay(2020, 7, 19)

// together
export const LOVE_DAYS = getPassDay(2020, 7, 27)

// wedding
export const WEDDING_DAYS = getPassDay(2022, 6, 4)

export const AGE = new window.URLSearchParams(window.location.search).get('age') ?? CUR_YEAR - BIRTHDAY_YEAR_YANG
export const audioUrl = new window.URLSearchParams(window.location.search).get('audio') ?? require("./assets/song.mp3").default
